/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans:wght@300;400;700&family=Merriweather:wght@300;400;700&display=swap');
/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
html {
  font-size: 15px;
}

body {
  font-family: 'Merriweather Sans', sans-serif!important;
  background-color: #F2F5F8!important;
  font-size: 15px;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
}

@media (max-width: 767px) {
  body {
    font-size: 14px;
  }
}

p {
  color: #575757;
  line-height: 1.7em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Merriweather', serif;
  color: #2a2a2a;
}

ul {
  padding-left: 0;
  margin: 0;
}

a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
}

a:hover,
a:focus {
  text-decoration: none;
}
ul{
  list-style: none;
}

img {
  max-width: 100%;
}

.owl-carousel .owl-item img {
  width: auto;
}

button:focus {
  outline: none;
}

.theme-btn,
.theme-btn-s2{
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    font-size: 1.06667rem;
    color: #fff;
    background: #EC615B;
    padding: 0 30px;
    letter-spacing: 1px;
    display: inline-block;
    border-radius: 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s;
  }
  .theme-btn:hover, 
  .theme-btn-s2:hover{
    background: #EC615B;
    color:#fff;
    text-decoration: none;
  }
  .theme-btn-s2{
    border-radius: 40px;
  }