/*-------------- Start device screen 1440 --------------*/

@media screen (max-width: 1920px) and (min-width: 1440px) {
    .hero-content {
        padding: 300px 0;
    }
}

@media screen and (min-width: 1440px) {
    .home-wrapper-3 .world-map {
        padding: 200px 0;
    }
}

@media screen and (max-width: 1440px) {
    .hero-shape {
        top: 83%;
    }

    .spreads {
        max-width: 1200px;
    }

    .faq .faq-img img {
        left: 1.3%;
    }

    .faq .faq-img::after {
        height: 186.3%;
    }
}

/*-------------- Start device screen 1366 --------------*/

@media screen and (max-width: 1366px) {
    .hero-shape {
        top: 84%;
    }
}

/*-------------- Start device screen 1024 --------------*/

@media screen and (max-width: 1024px) {
    .header .menu li:not(:last-child) {
        margin-right: 10px;
    }

    .header .menu li {
        padding-left: 10px;
    }

    .header .btn {
        min-width: 130px;
        height: 55px;
        line-height: 55px;
    }

    .hero-content h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .hero-content p {
        margin: 25px 0;
    }

    .hero-shape {
        top: 86%;
    }

    .about-area {
        padding: 100px 0;
    }

    .spreads {
        padding: 100px 30px;
        margin-bottom: 0;
    }

    .symptom {
        padding: 150px 0;
    }

    .single-world-status .icon {
        margin-right: 15px;
    }

    .single-member .img {
        height: 212px;
        width: 270px;
    }

    .blog::after {
        height: 80%;
        left: -20%;
    }

    .home-wrapper-3 .symptom {
        padding: 150px 0;
    }

    .faq {
        background: linear-gradient(35.28deg, #142863 5.29%, #1C388D 77.59%);
    }

    .faq .faq-img::after {
        width: 100%;
        left: 32px;
        height: 161.2%;
    }

    .recent-post .post-content h6 a {
        font-size: 14px;
    }

    .contact-address-2 .contact-info {
        min-height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: unset;
        margin: 0;
    }
}

/*-------------- Start device screen 991 --------------*/

@media screen and (max-width: 991px) {
    .hero-img {
        top: 25%;
    }

    .hero-shape {
        top: 91%;
    }

    .spreads .section-title {
        margin-bottom: 50px;
    }

    .spreads {
        padding: 100px 30px 130px;
    }

    .single-symptom {
        display: block;
        padding: 15px;
        clip-path: unset;
        text-align: center;
        min-height: 330px;
    }

    .single-symptom .icon {
        width: unset;
        margin: 0 auto 30px;
    }

    .blog {
        padding: 150px 0 50px;
    }

    .blog::after {
        width: 100%;
        height: 106%;
        left: 0;
        clip-path: unset;
        top: 0;
        z-index: -3;
    }

    .single-blog .img {
        margin-bottom: 30px;
    }

    .single-blog .img img {
        width: 100%;
        height: 250px;
        object-fit: cover;
    }

    .footer {
        position: relative;
        z-index: 1;
    }

    .top-footer {
        padding: 180px 0 100px;
    }

    .footer-logo {
        margin-bottom: 50px;
        display: inline-block;
    }

    .social ul {
        justify-content: flex-start;
    }

    .footer-menu ul {
        justify-content: flex-end;
    }

    .home-wrapper-3 .hero {
        background: linear-gradient(46.79deg, #142863 4.85%, #1C388D 92.09%);
    }

    .home-wrapper-3 .hero .hero-content {
        padding: 0 0 100px;
    }

    .header .btn {
        min-width: 120px;
        height: 45px;
        line-height: 45px;
    }

    .home-wrapper-3 .symptom {
        padding: 100px 0;
    }

    .home-wrapper-3 .prevention {
        padding: 100px 0 50px;
    }

    .home-wrapper-3 .section-title h3 {
        font-size: 18px;
        line-height: 30px;
    }

    .home-wrapper-3 .team {
        padding: 50px 0 100px;
    }

    .faq {
        padding: 100px 0;
    }

    .home-wrapper-3 .blog {
        padding-top: 100px;
    }

    .home-wrapper-3 .world-map {
        background: linear-gradient(205.59deg, #1C388D 19.57%, #142863 100%);
    }

    .home-spreads-2 {
        margin-top: 0;
        border-radius: 0;
        padding: 50px 30px 80px;
    }

    .home-two-hero .hero-content h1 {
        font-size: 36px;
        line-height: 50px;
    }

    .home-two-hero .hero-content {
        text-align: center;
        padding: 0 0 50px;
    }

    .home-two-hero .hero-content span {
        font-size: 18px;
        width: 170px;
        height: 40px;
        line-height: 40px;
        margin: 0 auto 30px;
    }

    .home-wrapper-2 .about-area {
        margin-top: 0;
        padding: 50px 0;
    }

    .home-wrapper-2 .section-title h3 {
        font-size: 24px;
        line-height: 30px;
    }

    .home-wrapper-2 .symptom {
        padding: 50px 0;
        background: linear-gradient(36.51deg, rgba(20, 40, 99, 0.9) 9.25%, rgba(28, 56, 141, 0.9) 76.13%);
    }

    .home-wrapper-2 .prevention {
        padding: 50px 0;
    }

    .home-wrapper-2 .world-map {
        padding: 50px 0;
        background: linear-gradient(36.51deg, rgba(20, 40, 99, 0.9) 9.25%, rgba(28, 56, 141, 0.9) 76.13%);
    }

    .home-wrapper-2 #wmap {
        margin: 0;
    }

    .home-wrapper-2 .faq,
    .home-wrapper-2 .team {
        padding: 50px 0;
    }

    .home-wrapper-2 .blog {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .home-wrapper-2 .top-footer {
        padding: 120px 0 50px;
    }

    .header-bg {
        padding: 30px 0;
    }

    .page-title h1 {
        font-size: 42px;
    }

    .widget-area {
        margin-top: 100px;
    }

    .author-info .content-inner,
    .taxonomy-area {
        display: block;
    }

    .taxonomy-area .taglist {
        margin-bottom: 15px;
    }

    .comment .img,
    .author-info .img {
        margin-right: 15px;
        width: 280px;
    }

    .taxonomy-area .taglist,
    .taxonomy-area .social-share {
        width: 100%;
    }

    .taxonomy-area .social-share ul {
        justify-content: flex-start;
    }

    .contact-address {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: -10px;
        margin-right: -10px;
    }

    .contact-info:not(:last-child) {
        margin-bottom: 0;
    }

    .contact-info {
        padding: 15px;
        min-height: 190px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: 0 10px;
        width: 33.33%;
    }



}

/*-------------- Start device screen 768 --------------*/

@media screen and (max-width: 768px) {
    .single-prevention .icon {
        margin-right: 10px;
    }
}

/*-------------- Start device screen 425 --------------*/

@media screen and (max-width: 425px) {
    .menu-close {
        top: 3%;
    }

    .hero-content {
        padding: 50px 0;
    }

    .hero-shape {
        top: 100%;
    }

    .about-area {
        margin-top: 50px;
    }

    .spreads .section-title {
        margin-bottom: 0;
    }

    .card.single-info {
        margin-top: 70px;
    }

    .symptom {
        padding: 50px 0;
    }

    .section-title h3 {
        font-size: 22px;
        line-height: 34px;
        margin-bottom: 20px;
    }

    .prevention {
        padding: 50px 0;
    }

    .prevention .right-img {
        display: none;
    }

    #wmap {
        height: 200px;
        margin: 40px 0;
    }

    .single-world-status {
        display: block;
        text-align: center;
    }

    .single-world-status .icon {
        margin-right: 0;
        margin-bottom: 15px;
    }

    .world-map {
        padding: 50px 0;
    }

    .single-member .img {
        margin: auto;
    }

    .single-member {
        margin-bottom: 65px;
    }

    .team {
        padding: 50px 0 0;
    }

    .blog {
        padding: 50px 0;
    }

    .top-footer img {
        display: none;
    }

    .footer-menu ul,
    .social ul {
        justify-content: center;
    }

    .footer-menu ul {
        margin-top: 50px;
    }

    .left-content {
        text-align: center;
    }

    .hero-content h1 {
        font-size: 26px;
        line-height: 36px;
    }

    .hero-content span {
        font-size: 18px;
    }

    .home-wrapper-3 .about-area {
        padding: 50px 0;
    }

    .spreads {
        padding: 50px 0 90px;
    }

    .home-wrapper-3 .symptom {
        padding: 50px 0;
    }

    .section-title h2 {
        font-size: 20px;
        margin-bottom: 15px;
    }

    .home-wrapper-3 .team {
        padding: 50px 0 0;
    }

    .faq {
        padding: 50px 0;
    }

    .faq .card-header .btn-link {
        font-size: 14px;
    }

    .faq .card-header {
        padding: 0 15px;
    }

    .faq .card-header .btn-link.collapsed::after,
    .faq .card-header .btn-link::after {
        font-size: 18px;
        right: 0;
    }

    .top-footer {
        padding: 120px 0 50px;
    }

    .home-wrapper-3 .blog {
        padding: 50px 0 0;
    }

    .home-wrapper-3 .hero .hero-content {
        padding: 0 0 50px;
    }

    .home-wrapper-3 .prevention {
        padding: 50px 0;
    }

    .team .section-title {
        margin-bottom: 100px;
    }

    .section-title>span {
        margin-bottom: 15px;
    }

    .section-title>p {
        font-size: 13px;
    }

    .blog .section-title {
        margin-bottom: 30px;
    }

    .top-footer .left-content h3 {
        font-size: 20px;
    }

    .footer-logo {
        margin-bottom: 30px;
    }

    .footer-menu ul {
        margin-top: 30px;
    }

    .single-member .content {
        margin-top: 30px;
    }

    .single-member .content h4 {
        font-size: 20px;
    }

    .team .row:last-child .col-lg-4.col-md-6:last-child .single-member {
        margin-bottom: 50px;
    }

    .faq .section-title {
        margin-bottom: 30px;
    }

    .spreads .col-md-4:first-child .single-info {
        margin-top: 30px;
    }

    .home-wrapper-2 .team .row:last-child .col-lg-4.col-md-6:last-child .single-member {
        margin-bottom: 0;
    }

    .home-two-hero .hero-content h1 {
        font-size: 24px;
        line-height: 40px;
    }

    .home-two-hero .hero-content .btn {
        min-width: 150px;
        height: 50px;
        line-height: 50px;
    }

    .page-title {
        background-position: top right;
    }

    .page-wrapper .spreads .card.single-info {
        margin-top: 0;
        margin-bottom: 30px;
    }

    .page-wrapper .spreads .row:last-child .col-lg-4:last-child .card.single-info {
        margin-bottom: 80px;
    }

    .page-wrapper .about-area {
        padding: 50px 0;
    }

    .page-wrapper .symptom {
        padding: 50px 0;
    }

    .page-wrapper .spreads {
        padding: 50px 0 10px;
    }

    .page-wrapper .team {
        padding: 50px 0 0;
    }

    .page-wrapper .team .row:last-child .col-lg-4.col-md-6:last-child .single-member {
        margin-bottom: 0;
    }

    .page-title {
        padding: 50px 0;
    }

    .mobile-menu {
        z-index: 9999;
    }

    .post-pagin {
        margin-top: 50px;
    }

    .widget-area {
        margin-top: 40px;
    }

    .author-info .content-inner ul {
        margin-top: 10px;
    }

    .comment,
    .comment .content-inner,
    .author-info .content-inner,
    .author-info {
        display: block;
    }

    .comment .img,
    .author-info .img {
        margin-bottom: 15px;
    }

    .comment-form {
        padding: 15px;
    }

    .comment-form .c-submit-btn {
        width: 100%;
    }

    .comment-form textarea.form-control {
        margin-top: 0;
    }

    .comment-form .c-submit-btn {
        margin-top: 15px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        padding: 0;
    }

    .comment .img,
    .author-info .img {
        margin-right: 15px;
        width: 80px;
    }

    .contact {
        padding: 50px 0 30px;
    }

    .contact-address {
        display: block;
        margin: 0;
    }

    .contact-info:not(:last-child) {
        margin-bottom: 30px;
    }

    .contact-info {
        width: 100%;
        margin: 0;
    }

    .contact-form .contact-submit-btn {
        width: 100%;
    }

    .contact-form {
        padding: 15px;
    }

    .error h1 {
        font-size: 120px;
    }

    .error p {
        font-size: 22px;
        padding: 30px 0 40px;
    }
}

/*-------------- Start device screen 375 --------------*/

@media screen and (max-width: 375px) {

    .faq .card-header .btn-link {
        font-size: 12px;
        overflow-wrap: break-word;
    }
}

/*-------------- Start device screen 320 --------------*/

@media screen and (max-width: 320px) {
    .hero-shape {
        top: 99%;
    }

    .hero-content h1 {
        font-size: 18px;
        line-height: 30px;
    }

    .hero-content p {
        font-size: 14px;
    }
}