
/* Spreads Section */
.home-spreads-2 {
    margin-top: -130px;
    box-shadow: 0px 21px 40px rgba(216, 214, 214, 0.25);
    border-radius: 0 50px 0 50px;
}
.home-spreads-2 .single-info{
    border-radius: 20px;
}
.home-spreads-2 .single-info::before {
    width: 100%;
}

.home-spreads-2{
    margin-bottom: 0px;
}

.home-spreads-2 .single-info::before{
    border-radius: 18px;
}