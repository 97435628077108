
.error {
    background: url('../../images/404-bg.svg') no-repeat bottom center / cover;
    padding: 100px 0;
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.error h1 {
    font-size: 200px;
    font-weight: 900;
}

.error p {
    font-size: 36px;
    font-weight: 700;
    padding: 30px 0 50px;
}